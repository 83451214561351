html, body, #root, .App { height: 100%; }
body {
  background-color: #000000;
}

.App-bg {
  animation: changeBg 80s infinite;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  filter: blur(2px);
  -webkit-filter: blur(2px);
  opacity: 0.3;
  background-position: top left;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
}
/* TODO: add a JS image preloader so the images don't flicker before switching */
@keyframes changeBg{
  0%, 100% {
    background-image: url("assets/images/bgs/bg1.jpg");
  }
  14% {
    background-image: url("assets/images/bgs/bg2.jpg");
  }
  28% {
    background-image: url("assets/images/bgs/bg3.jpg");
  }
  42% {
    background-image: url("assets/images/bgs/bg4.jpg");
  }
  56% {
    background-image: url("assets/images/bgs/bg5.jpg");
  }
  70% {
    background-image: url("assets/images/bgs/bg6.jpg");
  }
  84% {
    background-image: url("assets/images/bgs/bg7.jpg");
  }
}

.App {
  background-size: cover;
  background-position: bottom right;
  position: relative;
  z-index: 5;
}

.App-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100px;
  background-color: rgba(255, 255, 255, 0.05);
}
.App-header .intro {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 40px;
}
.App-header .intro-mobile {
  display: none;
  flex-direction: column;
  margin: 0;
  padding: 20px;
}
.App-header .intro-mobile .definition {
  font-size: 14px;
}
.App-header .intro .MuiTypography-root {
  color: #A9A9A9;
  font-size: 1.2rem;
}

.App-header ul {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 40px;
  list-style: none;
  text-align: right;
}

.App-header ul li {
  padding: 0.2rem 0;
  margin: 0;
}
.App-header a {
  color: #A9A9A9;
  font-size: 1.2rem;
  text-decoration: none;
}
.App-header a:hover .intro .MuiTypography-root {
  color: white;
}
.App-header ul li a:hover {
  color: white;
}

.App-content {
  color: #A9A9A9;
  width: 100%;
  height: calc(100vh - 150px);
}
.App-content .MuiTypography-gutterBottom {
  margin-bottom: 1rem;
}

.App-container {
  padding: 40px;
}

.About {
  color: white;
  padding: 1rem;
}

.Profile {
  color: white;
  padding: 1rem;
}

#root a {
  color: #A9A9A9;
  text-decoration: none;
}
#root a:hover {
  color: white;
}

.Mapchart svg {

}

@media only screen and (max-width: 700px) {
  .App-header {
    height: 120px;
    overflow: hidden;
  }
  .App-header .intro {
    display: none;
  }
  .App-header .intro-mobile {
    display: flex;
  }
  .App-header ul {
    padding: 20px 20px 20px 10px;
  }
  .App-container {
    padding: 20px;
  }
  .Profile {
    padding: 0;
  }
}